
/*  NAVBAR */ 
.navbar {
    height: 90px;
    width: 100%;
    position: fixed;
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
    background-color: white;
    border-bottom-style: solid;
    border-bottom-color: lightgray;
    border-width: 1px;
    overflow: hidden;
    box-shadow: 10px 0px 10px;
    z-index: 10;
}

.navbarLogo{
    height: 110px;
    float: left;
    margin-top: -27px;
    margin-left: 0;
    margin-right: -40px;
    object-fit: contain;
}

.navTitle {
    font-size: 40px;
    font-family: Gloock; /*ntr, days one, antic, fahkwang, galdeano */
    margin-top: 15px;
    margin-left: -1%;
    float: left;
}
.ul {
    list-style: none
}

.content {
    padding-top: 40px;
}

.button {
    font-size: 22px;
    border: none;
    background-color: white;
    color: black;
    margin-right: 40px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.buttonList {
    float: right;
    margin-right: 3%;
    margin-top: 20px;
}

.button:hover {
    color: rgb(198, 55, 44);
}

.bottomButton:hover{
    color: rgb(198, 55, 44);
}   


/* HOME */

.bottomButton{
    font-size: 20px;
    border: none;
    background-color: white;
    color: black;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.pageBottomHeadline{
    text-align: center;
    align-items: center;
}

.pageBottomText{
    text-align: center;
    align-items: center;
    font-family: "Alike", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
}

.bottomLogo{
    align-items: center;
    width: 70%;
}

.homePage{
    padding-bottom: -1000px;
}

.homeImg {
    width: 100%;
    height: 800px;
    object-fit: cover;
    margin-top: 65px;
}
.homeBlock{
    background-color: white;
    padding-bottom: 135px;
    height: 100px;
    margin-top: -550px;
    background-color: rgba(0, 0, 0, .1);
    text-align: center;
}

.homeTitle {
    font-size: 70px;
    color: white;
    margin-top: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.homeMission {
    text-align: center;
    width: 50%;
    margin-left: 25%;
    font-size: 29px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    margin-top: -10px;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.homeMethodSpan {
    width: 100%;
    background-color: rgb(33, 33, 73);
    /* margin-bottom: 200px; */
    /* padding-bottom: 40px; */
}

.flexContainer{
    display: flex;
    width: 100%;
    align-items: flex-start;
}

.flexContainer2{
    display: flex;
    width: 100%;
    align-items: center;
}

.flexBox{
    flex: 1;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
}

.homeMethodTitle {
    color: white;
    text-align: center;
    margin-top: 280px;
    padding-top: 50px;
    font-size: 40px;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;

}

.homeMethodHeadline1 {
    color: white;
    text-align: center;
    margin-top: 50px;
    width: 100%;
    font-size: 50px;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.homeMethod {
    color: white;
    text-align: center;
    margin-top: 50px;
    width: 100%;
    font-size: 25px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.homeContentSpan{
    background-color: rgb(234, 223, 210);
}

.homeContent{
    align-items: center;
    text-align: center;
    color: black;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); */
    font-family: "Alike", serif;
    font-weight: 500;
    font-style: normal;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 45px;
}

.homeContentSub{
    color: black;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); */
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    font-size: 25px;
}

.contentImg{
    max-width: 100%;
    height: 100%;
}

.homeSec2{
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 60px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .6);
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    
    padding-bottom: 60px;
}

.homeSec2Title{
    font-size: 40px;
}

.homeSec2Body{
    font-size: 30px;
}


.homeSec2Sub{
    font-size: 20px;
}

.homeSec3{
    padding-top: 100px;
    color: black;
    /* margin-top: -19px; */
    padding-top: 60px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding-bottom: 30px;
    background-color: rgb(234, 223, 210);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);



}

.homeSec4{
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.homeSec4Title{
    font-size: 50px;
}

.homeSec4Body{
    font-size: 30px;
}

.bottomBar{
    background-color: rgb(33, 33, 73);
    padding-bottom: 25px;
    z-index: 0;
}

.bottomBarFill{
    background-color: white;
    padding-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
}

.topBar{
    background-color: rgb(33, 33, 73);
    padding-top: 25px;
    z-index: 0;
}

.topBarFill{
    background-color: white;
    padding-top: 25px;
    margin-left: 25px;
    margin-right: 25px;
}
/* ABOUT US */

.aboutBlock{
    background-color: rgb(33, 33, 73);
    color: white;
    /* padding-bottom: 50px; */
    padding-top: 80px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}
.aboutTitle{
    text-align: center;
    font-family: "Alike", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
}

.aboutBody{
    text-align: left;
    margin-left: 17.5%;
    width: 65%;
    font-size: 29px;
    text-indent: 50px;
}

.aboutTitle2{
    text-align: center;
    font-family: "Alike", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color:white;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 1);

    margin-top: 30px;
}

.aboutTitle3{
    text-align: center;
    font-family: "Alike", serif;
    font-weight: 600;
    font-style: normal;
    font-size: 40px;
    color:black;
    /* text-shadow: 2px 2px 40px rgba(0, 0, 0, 1); */

}
.aboutTitle5{
    margin-left: 3%;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color:#195de6;
    margin-top: 300px;
}

.aboutBottomImg{
    height: 100px;
}

.investorsHeadline{
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 33%;
    font-size: 40px;
    border-bottom-style: solid;
    border-color: black;

}

.aboutBlockInv{
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 80px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 0);

}

.investorsMid{
    background-color: rgb(234, 223, 210);
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 1px;
    padding-bottom: 1px;
    color: black;
}

.investorsSec2{
    /* background-color: rgb(234, 223, 210); */
    background-color: rgb(33, 33, 73);
    color: white;
    text-shadow: 2px 2px 40px rgba(0, 0, 0, 1);
}
/* Careers Block */

.careersBlock{
    overflow:hidden;
    /* height: 100vh; */

}

.contactBanner{
    width: 100%;
    margin-top: -35px;
}

.contactTitle{
    color: white;
    text-align: center;
    justify-content: center;
    margin-top: -475px;
    font-size: 80px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-style: normal;
}

.contactListing{
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-weight: 400;
}

.linkedInIconContact{
    width: 2%;
    padding: 5px;
}

.emailIconContact{
    width: 1.9%;
    padding: 5px;
}

.telegramIcon{
    width: 18%;
    margin-right: 5px;
    margin-bottom: -7px;
}

.emailIcon{
    width: 8%;
    margin-right: 5px;
}

.contactBlock{
    align-items: center;
    margin-left: 40%;
}
.contactInfo{
    text-align: center;
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 80px;
    padding-bottom: 30px;
    align-items: center;
}

body.Contact{
    background-color: #f2f4fc;
}

a {
    color: white;
}