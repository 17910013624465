
.main{
    background-color: white;
    height: 50px;
    width: 100%;
    position:fixed;
    z-index: 100;
    box-shadow: 5px 2px 20px black;   
}

.navLogo{
    float: left;
    height: 100%;
}

.ul {
    list-style: none
}
/* 
.content {
    padding-top: 0px;
} */

.button {
    font-size: 50px;
    border: none;
    background-color: white;
    color: black;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    margin-bottom: 50px;
}

.buttonList {
    text-align: center;
    /* height: 100%; */
    align-items: center;
    margin-top: 40%;
}

.button:hover {
    border-bottom-style: solid;
    color: black;
}


/* HOME */

.contentImg{
    width: 100%;
}

.homeBlock{
    background-color: white;
}

.homeImg {
    /* Have the sidebar toggle under picture, move image to the top of the screen */
    margin-top: 50px;
    width: 100%;
    max-height: 100%;
}

.homeMission {
    text-align: center;
    margin-left: 10%;
    width: 80%;
    font-size: 15px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.homeTitle {
    color: white;
    text-align: center;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    z-index: 1;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    margin-top: -202px;
    /* padding-top: 20px; */
}

 .homeMethodImg {
    width: 90%;
    margin-left: 5%;
    box-shadow: 10px 10px 5px lightgray;
    /* position: relative; */
    z-index: 1;
}

.homeMethodSpan {
    width: 100%;
    background-color: rgb(33, 33, 73);
    padding-bottom: 50px;
}

.homeMethodTitle {
    color: white;
    text-align: center;
    /* margin-top: 100px; */
    font-size: 40px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    padding-top: 20px;
}

.homeMethodTitle2{
    color: black;
    text-align: center;
    /* margin-top: 100px; */
    font-size: 40px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
    padding-top: 20px;
}

.homeMethodHeadline1{
    text-align: center;
    color: white;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-size: 30px;
}

.homeMethodHeadline2{
    text-align: center;
    color: black;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
    font-size: 30px;
}

.homeMethod{
    color: white;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    text-align: center;
    font-size: 20px;
    margin-left: 5%;
    margin-right: 5%;
}

.homeMethod2{
    color: black;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
    text-align: center;
    font-size: 20px;
    margin-left: 5%;
    margin-right: 5%;
}

.homeContentSpan{
    background-color: rgb(234, 223, 210);
    padding-bottom: 20px;
}

.homeContent{
    color: white;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    text-align: center;
    font-size: 40px;
    margin-top: -275px;
    margin-bottom: 200px;
}

.homeContentSub {
    color: black;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);

    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

.homeSec2{
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 10px;
    padding-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}

.homeSec3{
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    background-color: rgb(234, 223, 210);
    color: black;
    padding-top: 10px;
    padding-bottom: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
}

.homeMethodCon{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -20px;
}
.homeMethodBar{
    height: 2px; /* Set the height of the bar */
    background-color: #26d076;
}
.homeMethodCon2{
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -20px;
}

.bar{
    background-color: rgb(33, 33, 73);
    padding-top: 25px;
}

.homeSec4{
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
}

.flexContainer2{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.flexBox{
    flex: 1;
    padding-left: 2%;
    padding-right: 2%;
    justify-content: center;
}

.bottomLogo{
    align-items: center;
    width: 100%;
}
/* .homeMethod { */
    /* CENTER!!! */
    /* color: black;
    text-align: left;
    margin-top: 50px;
    width: 90%;
    margin-left: 5%;
    font-size: 25px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal; */
/* } */

/* .homeMethod2 { */
    /* Center this */
    /* color: black;
    text-align: left;
    margin-top: 50px;
    width: 90%;
    margin-left: 5%;
    font-size: 25px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal; */
/* } */

/* ABOUT US */

.aboutBlock{
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
}
.aboutTitle{
    /* margin-left: 3%; */
    text-align: center;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    margin-top: 30px;
}

.aboutBody{
    /* Center, center, center */
    text-align:left;
    word-spacing: 5px;
    text-justify: distribute;
    width: 90%;
    margin-left: 5%;
    font-size: 29px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    text-indent: 50px;
}

.investorsMid{
    background-color: rgb(234, 223, 210);
    padding-top: 1px;
    padding-bottom: 1px;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0);
}

.investorsHeadline{
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    text-align: center;
    font-size: 40px;
    border-bottom-style: solid;
    border-color: black;
    
}

.aboutBody2{
    /* Center, center, center */
    text-align:left;
    word-spacing: 5px;
    text-justify: distribute;
    width: 85%;
    margin-left: 7.5%;
    font-size: 29px;
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
}

.aboutTitle2{
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    text-align: center;
    color: white;
    margin-top: 50px;
}

.aboutTitle3{
    /* Center! */
    font-family: "Alike", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 40px;
    color:black;
    margin-top: 50px;
}

/* Careers Block */

.contactBanner{
    width: 100%;
    margin-top: 50px;
}

.contactTitle{
    color: white;
    text-align: center;
    justify-content: center;
    margin-top: -375px;
    font-size: 40px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-style: normal;
}

.contactInfo{
    text-align: center;
    background-color: rgb(33, 33, 73);
    color: white;
    padding-top: 90px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
    font-family: "Alike", serif;
    font-style: normal;
}

.linkedInIconContact{
    width: 5%;
    padding: 5px;
}

.emailIconContact{
    width: 4.9%;
    padding: 5px;
}